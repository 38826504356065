import React from 'react';
import './index.scss';

const TripleColumnBlock = (props) => {
  return (
    <div className={`triple-column-block`}>
      <div className={`wrapper content ${props.textPadded ? 'padded' : ''}`}>
        <div className={`text-left ${props.centered ? 'centered' : ''}`}>
          {props.textLeft}
        </div>
        <div className={`text-center ${props.centered ? 'centered' : ''}`}>
          {props.textCenter}
        </div>
        <div className={`text-right ${props.centered ? 'centered' : ''}`}>
          {props.textRight}
        </div>
      </div>
    </div>
  );
}

export default TripleColumnBlock;

TripleColumnBlock.defaultProps = {
  centered: false,
  textPadded: false,
  textLeft: '',
  textCenter: '',
  textRight: '',
}
