import React from 'react';
import {
  QuickLinkButton,
} from "@components/buttons"

const GlobalQuickLinks = props =>  {
  return (
        <div>
          <h2>Free Webinars</h2>
          <p>
            Register for my free webinars where I'll teach you how you can grow your business with white-label and fractional marketing.
          </p>
		
					<h3>How Robust Keyword Research Can Transform Your Clients' Marketing Efforts</h3>
					<p>This webinar goes into the transformative power of robust keyword research in enhancing marketing strategies for clients.</p>
          <p>
            <QuickLinkButton
              content={"Register"}
              url={"https://websuasion.easywebinar.live/white-label-keyword-research-registration"}
              icon={"calendar"}
            />
          </p>
							
					<h3>Leveraging Fractional Marketing For Scalability and Savings</h3>
					<p>This webinar goes into the transformative power of robust keyword research in enhancing marketing strategies for clients.</p>
          <p>
            <QuickLinkButton
              content={"Register"}
              url={"https://websuasion.easywebinar.live/fractional-marketing-registration"}
              icon={"calendar"}
            />
          </p>
							
					<h3>Growing Your Marketing Firm With White Label Services</h3>
					<p>This webinar focuses on how marketing firms can leverage white label services to scale their operations and attract larger clients.</p>
          <p>
            <QuickLinkButton
              content={"Register"}
              url={"https://websuasion.easywebinar.live/white-label-services-registration"}
              icon={"calendar"}
            />
          </p>
							
          <h2>Subscribe to The Marketing Mingle</h2>
          <p>
            I talk with Branding and Marketing professionals about changes, trends, and techniques that have helped them and their clients.
          </p>
          <p>
            <QuickLinkButton
              content={"Podcast"}
              url={"/podcast/"}
              icon={"play"}
            />
          </p>
        </div>
  );
}

export default GlobalQuickLinks

GlobalQuickLinks.defaultProps = {
  textPadded: false,
  text: "",
}